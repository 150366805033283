html {
	background-color: #fff;
}

.header {
	position: absolute;
}
.header__2 .gnav__li {
	display: none !important;
}
.header__1 .right {
	display: none !important;
}

.main_column {
	padding-right: 0 !important;
	border-right: 0 none !important;
}

.sub_column {
	display: none !important;
}

.footer .row._2,
.footer .row._3 {
	display: none !important;
}
.footer__bannerlink, .footer__sp_linklist, .footer__linklist2 {
	display: none !important;
}

.root-index .header_container {
	display: none !important;
}

.noprint {
	display: none !important;
}